import React, { useState, useEffect, useRef } from 'react';
import { Loader } from "@googlemaps/js-api-loader"

const svgMarker = {
    path: "M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z",
    fillColor: "#305AA5",
    fillOpacity: 1,
    strokeWeight: 0,
    rotation: 0,
    scale: 3,
};

const highlightedMarker = {
  ...svgMarker,
  fillColor: "#305AA5",
  scale: 4,
};

const GoogleMapWithMarkers = ({ selectedLocation, locations }) => {
    const apiKey = 'AIzaSyDcSu2bzf2JJpuihcWCxPT6wFAVe7a451c';
    // const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
    const mapRef = useRef(null);
    const [map, setMap] = useState(null);
    const [markers, setMarkers] = useState([]);

  useEffect(() => {
    const loader = new Loader({
        apiKey: apiKey,
        version: "weekly",
    });

    loader.load().then((google) => {
        const mapInstance = new google.maps.Map(mapRef.current, {
            center: locations[0] ? { lat: Number(locations[0].lat), lng: Number(locations[0].lng) } : { lat: 37.7749, lng: -122.4194 },
            zoom: 17,
        });

        setMap(mapInstance);

        const newMarkers = locations.map((location) => {
            const marker = new google.maps.Marker({
                position: { lat: Number(location.lat), lng: Number(location.lng) },
                map: mapInstance,
                icon: svgMarker,
                title: location.name,
            });

            const infoWindowContent = `
                <div class="custom-info-window">
                    <button class="info-window-close-btn">Close</button>
                    <h3 class="info-window-title">${location.name}</h3>
                    <p class="info-window-text-small">${location.address}, ${location.city} ${location.zip}</p>
                    <p class="info-window-text"><a href=${location.gmb} target="_blank">Learn more</a></p>
                </div>
            `;

            const infoWindow = new google.maps.InfoWindow({
                content: infoWindowContent,
                maxWidth: 200,
                disableAutoPan: true,
            });

            google.maps.event.addListener(infoWindow, 'domready', () => {
                const closeBtn = document.querySelector('.gm-ui-hover-effect m-0 p-0');
                if (closeBtn) {
                    closeBtn.style.display = 'none';
                }

                const customCloseBtn = document.querySelector('.info-window-close-btn');
                if (customCloseBtn) {
                    customCloseBtn.addEventListener('click', () => {
                        infoWindow.close();
                    });
                }
            });

            marker.addListener("click", () => {
                infoWindow.open(mapInstance, marker);
            });

            return { marker, infoWindow };
        });

        setMarkers(newMarkers);
    });
}, [locations]);

useEffect(() => {
  if (map && selectedLocation) {
      const lat = Number(selectedLocation.lat);
      const lng = Number(selectedLocation.lng);

      if (isNaN(lat) || isNaN(lng)) {
          console.error("Invalid lat or lng:", selectedLocation.lat, selectedLocation.lng);
          return;
      }

      try {
          map.panTo({ lat, lng });
          map.setZoom(16);

          markers.forEach(({ marker, infoWindow }) => {
              const markerLat = marker.getPosition().lat();
              const markerLng = marker.getPosition().lng();
              
              if (Math.abs(markerLat - lat) < 0.0001 && Math.abs(markerLng - lng) < 0.0001) {
                  marker.setIcon(highlightedMarker);
                  infoWindow.open(map, marker);
              } else {
                  marker.setIcon(svgMarker);
                  infoWindow.close();
              }
          });
      } catch (error) {
          console.error("Error updating map:", error);
      }
  }
}, [selectedLocation, map, markers]);

  return (
    <>
    <style>
        {`
            .custom-info-window {
                padding: 10px;
                background-color: #E5E5E5;
                border-radius: .5rem;
                box-shadow: none;
            }
            .info-window-title {
                font-size: 1.5rem;
                font-weight: bold;
                margin-bottom: 5px;
                color: #000000;
            }
            .info-window-text {
                font-size: 1rem;
                margin: 4px 0;
                color: #000000;
            }
            
            .info-window-text-small {
                font-size: .84rem;
                margin: 4px 0;
                color: #000000;
            }
        `}
    </style>
    <div style={{ display: 'flex', height: '100%', width: '100%',}}>
      <div ref={mapRef} style={{ flex: 1 }}></div>
    </div>
    </>
  );
};

export default GoogleMapWithMarkers;
